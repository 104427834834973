import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {NavLink, useLocation} from "react-router-dom";
import config from '../../config/main.config';
import EditTextComponent from "../../components/editText/EditTextComponent";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";
import {Card} from "semantic-ui-react";
import {jumpToTop, regexpMail} from "../../helper/util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";


const namespace = 'contact';

function handleChange(event, type, setContactPerson, contactPerson) {
    let value = event.target.value;
    setContactPerson({...contactPerson, [type]: value});
}

async function submitMessage(contactPerson, message, inputErrors, setInputErrors, t, mode) {
    let canSend = true;
    let success = false;
    let response = {};
    if (contactPerson.email.match(regexpMail) && contactPerson.email.length > 0) {
        setInputErrors({...inputErrors, mailError: false});
    } else {
        setInputErrors({...inputErrors, mailError: true});
        canSend = false;
    }
    if (canSend) {
        //Send to server
        let data = {
            name: contactPerson.firstName + ' ' + contactPerson.lastName,
            subject: message.subject,
            email: contactPerson.email,
            text: message.message,
            newsletter: message.newsletterCheck,
            isRetry: false,
            to: mode
        };
        response = await fetch(config.BASE_URL + 'contact', {
            method: 'POST', headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*",
            }, body: JSON.stringify(data)
        })
        if (response.status >= 200 && response.status < 300) {
            success = await sendConfirmation(contactPerson, message.message, message.newsletterCheck, t, mode);
        }
    }
    return success;
}

async function sendConfirmation(contactPerson, message, newsletter, t, mode) {
    let success = 0;
    let response;
    let text = t("contact:contact.confirmation_mail_head");
    text += " " + contactPerson.firstName + " " + contactPerson.lastName + ", \n";
    text += t("contact:contact.confirmation_mail_pre");
    text += "\n\"" + message + "\"\n";
    text += t("contact:contact.confirmation_mail_post");
    if (newsletter) text += "\n" + t("contact:contact.confirmation_newsletter");
    text += "\n\n" + t("contact:contact.confirmation_mail_footer");
    let subject = t("contact:contact.confirmation_subject");
    let data = {
        name: contactPerson.firstName + ' ' + contactPerson.lastName,
        subject: subject,
        email: contactPerson.email,
        text: text,
        newsletter: newsletter,
        isRetry: false,
        to: mode
    };
    response = await fetch(config.BASE_URL + 'contact/confirm', {
        method: 'POST', headers: {
            'Accept': 'application/json', 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*",
        }, body: JSON.stringify(data)
    })
    if (response.status >= 200 && response.status < 300) success = 1;
    return success;
}

const ContactPage = (props) => {
    const {t, type, i18n} = props;
    const {hash} = useLocation();
    const [renderMode, setRenderMode] = useState(type ? type : hash);
    const [success, setSuccess] = useState(-1);
    const [sending, setSending] = useState(false);
    const [contactPerson, setContactPerson] = useState({
        firstName: '', lastName: '', phone: '', email: '',
    });
    const [message, setMessage] = useState({
        subject: '', message: '', conditionsChecked: false, newsletterCheck: false,
    });
    const [inputErrors, setInputErrors] = useState({
        lastNameError: false, phoneError: false, subjectError: false, messageError: false, mailError: false
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            jumpToTop(window)
        }
    }, []);

    useEffect(() => {
        setSuccess(-1);
    }, [message, contactPerson]);


    const renderContact = (mode) => {
        return (<div className={'row centered'}>
            <div className={'fourteen wide column'}>
                <h2><EditTextComponent id={`${namespace}-${mode}-title`} namespace={namespace}/></h2>
                <div className={'row'}>
                    <div className={'column contact_table'}>
                        <p className={"contact_mandatory_paragraph"}>{t('contact:contact.mandatory')}</p>
                        <form className={'ui form contact-form'}>
                            <div className={'equal width fields'}>
                                <div className="field">
                                    <label className={"text-primary"}>{t('contact.first_name')}</label>
                                    <div className="ui input">
                                        <input
                                            key={"first_name"}
                                            type="text"
                                            value={contactPerson.firstName}
                                            onChange={event => {
                                                handleChange(event, 'firstName', setContactPerson, contactPerson);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="field">
                                    <label className={"text-primary"}>{t('contact.last_name')}</label>
                                    <div className="ui input">
                                        <input
                                            key={"last_name"}
                                            type="text"
                                            value={contactPerson.lastName}
                                            onChange={event => {
                                                handleChange(event, 'lastName', setContactPerson, contactPerson);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'equal width fields'}>
                                <div className={inputErrors.mailError ? 'error field' : 'field'}>
                                    <label className={"text-primary"}>{t('contact.mail') + '*'}</label>
                                    <div className="ui input">
                                        <input
                                            key={"mail"}
                                            type="text"
                                            value={contactPerson.email}
                                            onChange={event => {
                                                handleChange(event, 'email', setContactPerson, contactPerson);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"field"}>
                                <label className={"text-primary"}>{t('contact.subject')}</label>
                                <div className={'ui input'}>
                                    <input key={"subject"} type={"text"}
                                           value={message.subject}
                                           onChange={event => {
                                               handleChange(event, 'subject', setMessage, message);
                                           }}/>
                                </div>
                            </div>
                            <div className="field">
                                <label className={"text-primary"}>{t('contact.message')}</label>
                                <div className="ui input">
                                                <textarea key={"message"} rows="3"
                                                          value={message.message}
                                                          onChange={event => {
                                                              handleChange(event, 'message', setMessage, message);
                                                          }}
                                                />
                                </div>
                            </div>
                            <div className="ui fitted checkbox"
                                 style={{
                                     marginTop: '15px', marginRight: '15px'
                                 }}>
                                <input
                                    key={"data_privacy"}
                                    tabIndex="0"
                                    type="checkbox"
                                    onChange={_ => setMessage({
                                        ...message, conditionsChecked: !message.conditionsChecked
                                    })}
                                    value={message.conditionsChecked}
                                />
                                <label className={"text-primary"}/>
                            </div>
                            {t('contact:contact.terms')}
                            <NavLink key={"data_privacy"}
                                     exact
                                     to={`/${i18n.language}/data-privacy`}
                                     className={'accept-terms-link'}>
                                {" " + t('contact:contact.data_privacy')}
                            </NavLink>
                        </form>
                    </div>
                </div>
                <div className={'row centered'}>
                    <div className={'middle aligned sixteen wide column'}>
                        <Button
                            style={{marginTop: "1rem"}}
                            disabled={!message.conditionsChecked || contactPerson.email === '' || success === 1 || !(/.+@.+\..+/g.test(contactPerson.email))}
                            type="submit"
                            circular
                            loading={sending}
                            onClick={() => {
                                setSuccess(-1);
                                setSending(true)
                                submitMessage(contactPerson, message, inputErrors, setInputErrors, t, mode.toUpperCase()).then((_success) => {
                                    setSending(false);
                                    setSuccess(_success);
                                });
                            }}>
                            {success === -1 ? t('contact:contact.submit').toUpperCase() : success === 1 ? t('contact:contact.sent') : t('contact:contact.failed')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>)
    }

    const renderPage = () => {
        switch (renderMode) {
            case '#stationaer':
                return (renderContact('stationaer'))
            case '#ambulant':
                return (renderContact('ambulant'))
            default:
                return (<div className={'row centered'}>
                    <div className={'fourteen wide wide column contact-choose-container'}>
                        <Card className={'contact-choose-item'} onClick={_ => {
                            setRenderMode('#ambulant')
                        }}>
                            <img className={'contact-choose-image'} src={config.BASE_URL_IMAGES + 'calendula.webp'}
                                 alt={'ambulant'}/>
                            <Card.Content style={{textAlign: 'center'}}>
                                <Card.Header>
                                    Ambulant
                                </Card.Header>
                                <Card.Description>
                                    Kontakt Ambulant
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card className={'contact-choose-item'} onClick={_ => {
                            setRenderMode('#stationaer')
                        }}>
                            <img className={'contact-choose-image'} src={config.BASE_URL_IMAGES + 'Hospiz.webp'}
                                 alt={'stationär'}/>
                            <Card.Content style={{textAlign: 'center'}}>
                                <Card.Header >
                                    Stationär
                                </Card.Header>
                                <Card.Description>
                                    Kontakt Stationär
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                </div>)
        }
    }

    return (<div className={'grid ui'} style={type ? {} : {paddingTop: '200px'}}>
            <div className={'row centered'}>
                <div className={'fourteen wide column'}>
                    <h1><EditTextComponent id={`${namespace}-header`} namespace={namespace}/></h1>
                    <EditHTMLComponent id={`${namespace}-sub-text`} namespace={namespace}/>
                </div>
            </div>
            {renderPage()}
        </div>)

}

export default withTranslation(['contact'])(ContactPage)

