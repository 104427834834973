import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Loadable from "react-loadable";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Label} from "semantic-ui-react";

import {fetchMissingTextById} from "../../helper/util";
import LoaderComponent from "../LoaderComponent";
import userStore from "../userManagement/userStore";
import {getCMSItemById} from "../../helper/util";
import {useServerData} from "../../state/serverDataContext";

const TextStoreTextEditor = Loadable({
    loader: () => import(/* webpackChunkName: 'TextStoreTextEditor' */ "./TextStoreTextEditor"),
    loading: () => LoaderComponent
});

const EditTextComponent = (props) => {
    const {id, i18n, namespace, className} = props;
    const [textStore, setTextStore] = useState(useServerData());
    const [textToShow, setTextToShow] = useState(getCMSItemById(textStore, id, i18n.language));
    const [modalOpen, setModalOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(userStore.isAdmin);

    useEffect(() => {
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
    }, []);



    useEffect(() => {
        setCanEdit(userStore.isAdmin);
    }, [userStore.isAdmin]);

    if (canEdit && typeof document !== "undefined") {
        return (
            <div>
                <Button className={"circular edit_button admin-button-primary"} color={'orange'}
                        icon={"edit outline"}
                        onClick={() => {
                            setModalOpen(true);
                        }}/>
                <TextStoreTextEditor id={id}
                                     modalOpen={modalOpen}
                                     textToShow={textToShow}
                                     namespace={namespace}
                                     onClose={(text) => {
                                         if (text !== "") {
                                             setTextToShow({
                                                 title: '',
                                                 description: '',
                                                 content: text.content,
                                                 isNamespace: namespace
                                             });
                                         }
                                         setModalOpen(false);
                                     }}
                />
                <div>
                    {typeof textToShow !== "undefined" ? textToShow.content : null}
                    {
                        textToShow.isNamespace === namespace ?
                            ""
                            : <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                                     color={"red"}>!</Label>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className={className ? className : ''}>
                {typeof textToShow !== "undefined" ? textToShow.content : null}
            </div>
        )
    }
}

EditTextComponent.propTypes =
    {
        id: PropTypes.string,
        namespace: PropTypes.string,
    };

EditTextComponent.defaultProps =
    {
        id: Math.random().toString(),
        namespace: "",
    };

export default withTranslation(['common'])(EditTextComponent)